import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../component/layout/Layout";
import { useEffect, useRef, useState } from "react";
import {
    useChangeHandler,
    useChangeImageHandler,
} from "../../../common/CommonUtil";
import { dogForm, dogLeaveButton } from "./DogUtil";
import useDogsService from "./useDogsService";
import {
    DogImageBox,
    DogProfileDetailBox,
    DogProfileImageBox,
    DogProfileLeftBox,
    DogProfileRightBox,
    DogProfileRightImageBox,
} from "./css/styled.DogProfile";
import DogProfileImage from "./DogProfileImage";
import { Box } from "../../../component/box/css/styled.box";
import { InputBox, SelectBox } from "../../../component/form/Form";
import useShelterService from "../shelter/useShelterService";
import { Li } from "../../../component/layout/css/styled.layout";
import { Button } from "../../../component/button/css/styled.button";
import { admin } from "../../../common/SessionUtil";
import DogProfileModal from "./DogProfileModal";

function DogProfile() {
    const navigate = useNavigate();
    let { shelterCode, dogCode } = useParams();
    const { state, setState, onChangeHandler } = useChangeHandler(dogForm);
    const { getDog, deleteDog, postDogImage, getDogImageList } =
        useDogsService();
    const { getShelterList } = useShelterService();

    const [image, setImage] = useState();

    const [shelterList, setShelterList] = useState([]);
    const [dogImageList, setDogImageList] = useState([]);

    useEffect(() => {
        const query = {
            shelterCode: shelterCode,
            dogCode: dogCode,
        };
        getDog({ query, setState });
        getDogImageList({ query, setDogImageList });
        getShelterList({ query, setShelterList });
    }, []);

    const onClickUpdate = () => {
        navigate(`/dog/update/${shelterCode}/${dogCode}`);
    };

    const onClickDelete = () => {
        const query = {
            dogCode: dogCode,
        };

        deleteDog({ query });
    };

    const button = () => {
        return (
            <>
                <Li>
                    <div style={{ cursor: "pointer" }} onClick={onClickUpdate}>
                        수정
                    </div>
                </Li>
                <Li>
                    <div style={{ cursor: "pointer" }} onClick={onClickDelete}>
                        삭제
                    </div>
                </Li>
            </>
        );
    };

    const { image: addDogImage, onChangeImageHandler } =
        useChangeImageHandler();
    const imageRef = useRef();

    const onClickAddDogImage = () => {
        if (admin()) {
            imageRef.current.click();
        } else {
            alert("권한이 없습니다");
        }
    };

    useEffect(() => {
        if (addDogImage !== undefined) {
            const dog = {
                shelterCode: shelterCode,
                dogCode: dogCode,
            };

            const save = new Blob([JSON.stringify(dog)], {
                type: "application/json",
            });

            const formData = new FormData();
            formData.append("save", save);
            formData.append("file", addDogImage);

            postDogImage(formData);
        }
    }, [addDogImage]);

    // 모달 이미지
    const [isModal, setIsModal] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [index, setIndex] = useState();
    const onClickImage = (imageUrl, index) => {
        setIsModal(!isModal);
        setImageUrl(imageUrl);
        setIndex(index);
    };

    useEffect(() => {
        if (isModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isModal]);

    return (
        <Layout button={button()}>
            {isModal && (
                <DogProfileModal
                    isModal={isModal}
                    setIsModal={setIsModal}
                    imageUrl={imageUrl}
                    index={index}
                    dogImageList={dogImageList}
                />
            )}
            <DogProfileDetailBox>
                <DogProfileLeftBox>
                    <DogProfileImageBox>
                        <DogProfileImage dog={state} setImage={setImage} />
                    </DogProfileImageBox>
                    <form>
                        <Box>
                            <InputBox
                                label="이름"
                                type="text"
                                width="300px"
                                labelWidth="30%"
                                height="40px"
                                disabled={true}
                                id="dogName"
                                value={state.dogName}
                                onChangeHandler={onChangeHandler}
                            />
                        </Box>
                        <Box>
                            <SelectBox
                                label="보호소"
                                width="300px"
                                labelWidth="30%"
                                height="50px"
                                disabled={true}
                                id="shelterCode"
                                value={state.shelterCode}
                                onChangeHandler={onChangeHandler}
                                options={shelterList}
                            />
                        </Box>
                        {sessionStorage.getItem("token") !== null ? (
                            <Box
                                style={{
                                    display: "flex",
                                    height: "80px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    ref={imageRef}
                                    accept="image/*"
                                    onChange={onChangeImageHandler}
                                />
                                <Button
                                    style={{
                                        width: "300px",
                                        height: "50px",
                                        border: "1px solid #ccc",
                                        borderRadius: "10px",
                                    }}
                                    type="button"
                                    onClick={() => onClickAddDogImage()}
                                >
                                    사진 추가하기
                                </Button>
                            </Box>
                        ) : null}
                    </form>
                </DogProfileLeftBox>
                <DogProfileRightBox>
                    {dogImageList.length > 0 ? (
                        <DogProfileRightImageBox>
                            {dogImageList.map((item, index) => (
                                <DogImageBox key={item.fileName}>
                                    <img
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "20px",
                                            overflow: "hidden",
                                            objectFit: "cover",
                                        }}
                                        src={item.fileUrl}
                                        onClick={() =>
                                            onClickImage(item.fileUrl, index)
                                        }
                                    />
                                </DogImageBox>
                            ))}
                        </DogProfileRightImageBox>
                    ) : (
                        <div>
                            등록된 강아지 사진이 없습니다. <br />
                            새로운 사진을 추가해 보세요!
                        </div>
                    )}
                </DogProfileRightBox>
            </DogProfileDetailBox>
        </Layout>
    );
}

export default DogProfile;
