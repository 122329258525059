import { useParams } from "react-router-dom";
import Layout from "../../../component/layout/Layout";
import { Li } from "../../../component/layout/css/styled.layout";
import { useEffect, useState } from "react";
import useDiaryService from "./useDiaryService";
import { Box } from "../../../component/box/css/styled.box";
import { InputBox } from "../../../component/form/Form";
import { DiaryBox, DiaryImageBox } from "./css/styled.Diary";

function Diary() {
    const { getDiary, deleteDiary } = useDiaryService();

    let { diaryCode } = useParams();

    const [diary, setDiary] = useState();
    useEffect(() => {
        const query = {
            diaryCode: diaryCode,
        };

        getDiary({ query, setDiary });
    }, []);

    const onClickDelete = () => {
        const query = {
            diaryCode: diaryCode,
        };
        deleteDiary({ query });
    };

    const button = () => {
        return (
            <>
                <Li>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => onClickDelete()}
                    >
                        삭제
                    </div>
                </Li>
            </>
        );
    };

    return (
        <Layout button={button()}>
            {diary && (
                <>
                    <DiaryBox>
                        <Box style={{ padding: "0 0 20px 0" }}>
                            <InputBox
                                label="제목"
                                type="text"
                                width="100%"
                                labelWidth="15%"
                                height="50px"
                                id="diaryTitle"
                                value={diary.diaryTitle}
                                disabled={true}
                            />
                            <InputBox
                                label="날짜"
                                type="text"
                                width="100%"
                                labelWidth="15%"
                                height="50px"
                                id="diaryDate"
                                value={diary.diaryDate}
                                disabled={true}
                            />
                        </Box>
                    </DiaryBox>
                    <DiaryImageBox>
                        {diary.fileUrlList.map((item, index) => (
                            <img
                                key={index}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflow: "hidden",
                                    objectFit: "cover",
                                }}
                                src={item}
                                alt={item}
                            />
                        ))}
                    </DiaryImageBox>
                </>
            )}
        </Layout>
    );
}

export default Diary;
