import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "./styled.Calendar.css";

function Calendars({ setDate }) {
    const [calendar, setCalendar] = useState(new Date());

    useEffect(() => {
        const year = calendar.getFullYear();
        const month = String(calendar.getMonth() + 1).padStart(2, "0");
        const day = String(calendar.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        setDate(formattedDate);
    }, [calendar]);

    return (
        <Calendar
            value={calendar}
            onChange={setCalendar}
            calendarType="gregory"
            prev2Label={null}
            next2Label={null}
            minDetail="year"
        />
    );
}

export default Calendars;
