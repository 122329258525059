import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../component/layout/Layout";
import { DogBox, DogImageBox, DogListBox, DogNameBox } from "./css/styled.Dog";
import useDogsService from "./useDogsService";
import { useEffect, useState } from "react";
import { Box } from "../../../component/box/css/styled.box";
import { Li } from "../../../component/layout/css/styled.layout";
import Pagination from "../../../component/pagination/Pagination";
import ShelterList from "./ShelterList";

function DogList() {
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState();
    const [dogList, setDogList] = useState([]);
    const { getDogList } = useDogsService();

    const [shelterCode, setShelterCode] = useState();

    useEffect(() => {
        const query = {
            userType: "admin",
            page: currentPage - 1,
            size: 20,
            shelterCode: shelterCode === undefined ? "" : shelterCode,
        };

        getDogList({ query, setDogList, setPage });
    }, [currentPage, shelterCode]);

    const onChagePage = (page) => {
        setCurrentPage(page);
    };

    const onClickNew = () => {
        navigate(`/dog/new`);
    };

    const onClickDog = (shelterCode, dogCode) => {
        navigate(`/dog/${shelterCode}/${dogCode}`);
    };

    const button = () => {
        return (
            <Li>
                <div style={{ cursor: "pointer" }} onClick={onClickNew}>
                    등록
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <ShelterList setShelterCode={setShelterCode} />
            {dogList.length > 0 ? (
                <DogListBox>
                    {dogList.map((item) => (
                        <DogBox
                            key={item.dogCode}
                            onClick={() =>
                                onClickDog(item.shelterCode, item.dogCode)
                            }
                        >
                            <DogImageBox>
                                {item.dogImageJson !== undefined ? (
                                    <img
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "100px",
                                            overflow: "hidden",
                                            objectFit: "cover",
                                        }}
                                        src={item.dogImageJson.fileUrl}
                                        alt={item.dogImageJson.fileName}
                                    />
                                ) : (
                                    <Box
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "20px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        이미지 없음
                                    </Box>
                                )}
                                <DogNameBox>{item.dogName}</DogNameBox>
                            </DogImageBox>
                        </DogBox>
                    ))}
                </DogListBox>
            ) : (
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        minHeight: "calc(100vh - 500px)",
                        marginBottom: "30px",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    등록된 강아지가 없습니다
                </div>
            )}
            {page && (
                <Pagination
                    currentPage={currentPage}
                    totalElements={page.totalElements}
                    onChangePage={onChagePage}
                />
            )}
            {/* <DogSearchBox>
                <Box>
                    <InputBox
                        label="강아지 이름"
                        type="text"
                        width="300px"
                        labelWidth="30%"
                        height="50px"
                        disabled={false}
                        id="dogName"
                        onChangeHandler={onChangeHandler}
                    />
                </Box>
                <Box>
                    <Button
                        style={{
                            width: "100px",
                            marginLeft: "50px",
                        }}
                        type="button"
                        onClick={() => onClickSearch()}
                    >
                        검색
                    </Button>
                </Box>
            </DogSearchBox> */}
        </Layout>
    );
}

export default DogList;
