import { axiosDelete, axiosGet, axiosPost } from "../CommonAPI";

export const postDiaryAPI = async ({ data, config }) =>
    axiosPost("/withthedogs/diary", data, config);

export const getDiaryListAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/diary/list", data, config);

export const getDiaryAPI = async ({ data, config }) =>
    axiosGet("/withthedogs/diary", data, config);

export const deleteDiaryAPI = async ({ data, config }) =>
    axiosDelete("/withthedogs/diary", data, config);
