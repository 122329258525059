import styled from "styled-components";

export const DiaryWriteBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    padding: 0 200px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0 20px;
    }
`;
