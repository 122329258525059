import { useNavigate } from "react-router-dom";
import { Li } from "../../../component/layout/css/styled.layout";
import { useEffect, useState } from "react";
import useBoardService from "../useBoardService";
import {
    NoticeBoardBox,
    PostAuthor,
    PostHeader,
    PostHeaderAuthor,
    PostHeaderTitle,
    PostItem,
    PostList,
    PostTitle,
} from "./css/styled.NoticeBoard";
import Layout from "../../../component/layout/Layout";
import Pagination from "../../../component/pagination/Pagination";

function NoticeBoard() {
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState();
    const [noticeList, setNoticeList] = useState([]);
    const { getNoticeBoardList } = useBoardService();
    useEffect(() => {
        const query = {
            page: currentPage - 1,
            size: 10,
        };
        getNoticeBoardList({ query, setNoticeList, setPage });
    }, [currentPage]);

    const onChagePage = (page) => {
        setCurrentPage(page);
    };

    const onClickBoard = (id) => {
        navigate(`/notice/board/${id}`);
    };

    const onClickWrite = () => {
        navigate("/notice/board/write");
    };

    const button = () => {
        return (
            <Li>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickWrite()}
                >
                    글쓰기
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <NoticeBoardBox>
                <PostList>
                    <PostHeader>
                        <PostHeaderTitle>제목</PostHeaderTitle>
                        <PostHeaderAuthor>작성자</PostHeaderAuthor>
                    </PostHeader>
                    {noticeList.length > 0 ? (
                        noticeList.map((item) => (
                            <PostItem
                                key={item.id}
                                onClick={() => onClickBoard(item.id)}
                            >
                                <PostTitle>{item.noticeBoardTitle}</PostTitle>
                                <PostAuthor>{item.userId}</PostAuthor>
                            </PostItem>
                        ))
                    ) : (
                        <PostItem>등록된 게시글이 없습니다.</PostItem>
                    )}
                </PostList>
            </NoticeBoardBox>
            {page && (
                <Pagination
                    currentPage={currentPage}
                    totalElements={page.totalElements}
                    onChangePage={onChagePage}
                />
            )}
        </Layout>
    );
}

export default NoticeBoard;
