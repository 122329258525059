import styled from "styled-components";

export const DiaryBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    padding: 0 200px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0 20px;
    }
`;

export const DiaryImageBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: block;
    width: 700px;
    margin: 10px auto;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 300px;
    }
`;
