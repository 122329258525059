import { useEffect, useRef, useState } from "react";
import {
    useChangeHandler,
    useChangeMultipleImageHandler,
} from "../../../common/CommonUtil";
import { Box } from "../../../component/box/css/styled.box";
import { InputBox, SelectBox } from "../../../component/form/Form";
import { Li } from "../../../component/layout/css/styled.layout";
import Layout from "../../../component/layout/Layout";
import useShelterService from "../shelter/useShelterService";
import { DiaryWriteBox } from "./css/styled.DiaryWrite";
import { diaryForm } from "./init";
import useDiaryService from "./useDiaryService";
import { DiaryImageBox } from "./css/styled.Diary";
import Calendars from "../../../component/calendar/Calendars";

function DiaryWirte() {
    const { state, onChangeHandler } = useChangeHandler(diaryForm);
    const { images, previews, onChangeMultipleImageHandler } =
        useChangeMultipleImageHandler();

    const [diaryDate, setDiaryDate] = useState();

    const [shelterList, setShelterList] = useState([]);
    const { getShelterList } = useShelterService();
    const { postDiary } = useDiaryService();

    useEffect(() => {
        getShelterList({ setShelterList });
    }, []);

    useEffect(() => {
        if (shelterList.length > 0) {
            state.shelterCode = shelterList[0].value;
        }
    }, [shelterList.length > 0]);

    const onClickWrite = () => {
        state.diaryDate = diaryDate;

        const create = new Blob([JSON.stringify(state)], {
            type: "application/json",
        });

        const formData = new FormData();
        formData.append("create", create);
        images.forEach((image) => {
            formData.append("files", image);
        });

        postDiary(formData);
    };

    const imageRef = useRef();
    const onClickImage = () => {
        imageRef.current.click();
    };

    const button = () => {
        return (
            <Li>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickWrite()}
                >
                    저장
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <DiaryWriteBox>
                <Box style={{ padding: "0 0 20px 0" }}>
                    <InputBox
                        label="제목"
                        type="text"
                        width="100%"
                        labelWidth="15%"
                        height="50px"
                        id="diaryTitle"
                        value={state.diaryTitle}
                        onChangeHandler={onChangeHandler}
                    />
                    <Calendars setDate={setDiaryDate} />
                    <SelectBox
                        label="보호소"
                        width="100%"
                        labelWidth="15%"
                        height="50px"
                        id="shelterCode"
                        value={state.shelterCode}
                        onChangeHandler={onChangeHandler}
                        options={shelterList}
                    />
                </Box>
                <Box style={{ padding: "0 0 20px 0" }}>
                    <Box
                        style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            borderRadius: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => onClickImage()}
                    >
                        이미지를 등록하세요
                    </Box>
                    <input
                        style={{ display: "none" }}
                        type="file"
                        multiple
                        ref={imageRef}
                        accept="image/*"
                        onChange={onChangeMultipleImageHandler}
                    />
                </Box>
                <DiaryImageBox>
                    {previews.map((item, index) => (
                        <img
                            key={index}
                            style={{
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                objectFit: "cover",
                            }}
                            src={item}
                            alt={item}
                        />
                    ))}
                </DiaryImageBox>
            </DiaryWriteBox>
        </Layout>
    );
}

export default DiaryWirte;
