import { useMutation } from "react-query";
import {
    deleteDiaryAPI,
    getDiaryAPI,
    getDiaryListAPI,
    postDiaryAPI,
} from "../../../api/service/DiaryServiceAPI";
import { useNavigate } from "react-router-dom";

function useDiaryService() {
    const navigate = useNavigate();

    const { mutate: postDiaryMutate } = useMutation(postDiaryAPI);
    const { mutate: getDiaryListMutate } = useMutation(getDiaryListAPI);
    const { mutate: getDiaryMutate } = useMutation(getDiaryAPI);
    const { mutate: deleteDiaryMutate } = useMutation(deleteDiaryAPI);

    // 일기 등록
    const postDiary = (data) => {
        postDiaryMutate(
            { data },
            {
                onSuccess: (data) => {
                    navigate("/diary/list");
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 일기 조회
    const getDiaryList = ({ query, setDiaryList, setPage }) => {
        getDiaryListMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setDiaryList(data.result.list);
                    setPage(data.result);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 일기 상세
    const getDiary = ({ query, setDiary }) => {
        getDiaryMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    setDiary(data.result);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 일기 삭제
    const deleteDiary = ({ query }) => {
        deleteDiaryMutate(
            { data: query },
            {
                onSuccess: (data) => {
                    navigate("/diary/list");
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    return {
        postDiary,
        getDiaryList,
        getDiary,
        deleteDiary,
    };
}

export default useDiaryService;
