import { jwtDecode } from "jwt-decode";
import { userToken } from "../../../common/SessionUtil";

export const userNavi = () => {
    let userNaviList = [];
    const token = userToken();

    if (token !== null) {
        const userMenuList = jwtDecode(token).userMenuList;
        userNaviList = naviList.filter((item) =>
            userMenuList.includes(item.menuId),
        );
    }

    const defaultNaviList = [
        {
            title: "Home",
            url: "/home",
        },
        {
            title: "공지사항",
            url: "/notice",
        },
        {
            title: "함께할개",
            url: "/dog",
        },
        {
            title: "함께했개",
            url: "/diary/list",
        },
    ];

    defaultNaviList.push(...userNaviList);

    return defaultNaviList;
};

const naviList = [
    {
        menuId: "201",
        title: "어드민",
        url: "/admin",
    },
];
