import styled from "styled-components";

export const DogSearchBox = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0 300px;

    @media (max-width: 768px) {
        justify-content: center;
        height: 50px;
        margin-bottom: 30px;
        padding: 0;
    }
`;

export const ShelterButton = styled.div`
    display: flex;
    width: 100px;
    height: 50px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #ccc;
`;

export const DogTitleBox = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
`;

export const DogListBox = styled.div`
    display: grid;
    min-height: calc(100vh - 450px);
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-top: 50px;
    padding: 0 400px;
    align-items: start;

    @media (max-width: 768px) {
        display: grid;
        min-height: calc(100vh - 450px);
        grid-template-columns: repeat(3, 1fr);
        margin-top: 0px;
        padding: 0 10px;
        margin-bottom: 30px;
    }
`;

export const DogMainBox = styled.div`
    display: block;
    min-height: calc(100vh - 100px);
    align-content: center;

    @media (max-width: 768px) {
        display: inline;
        height: 100%;
    }
`;

export const DogBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        height: 150px;
    }
`;

export const DogImageBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: block;
    width: 120px;
    height: 120px;
    margin: 10px auto;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
`;

export const DogNameBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
`;

export const DogButtonBox = styled.div`
    display: flex;
`;
