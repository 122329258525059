import { useNavigate } from "react-router-dom";
import { Li } from "../../../component/layout/css/styled.layout";
import Layout from "../../../component/layout/Layout";
import useDiaryService from "./useDiaryService";
import { useEffect, useState } from "react";
import {
    DiaryDate,
    DiaryHeader,
    DiaryHeaderDate,
    DiaryHeaderTitle,
    DiaryItem,
    DiaryListBox,
    DiaryTitle,
} from "./css/styled.DiaryList";
import Pagination from "../../../component/pagination/Pagination";

function DiaryList() {
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState();
    const [diaryList, setDiaryList] = useState([]);
    const { getDiaryList } = useDiaryService();
    useEffect(() => {
        const query = {
            page: currentPage - 1,
            size: 10,
        };
        getDiaryList({ query, setDiaryList, setPage });
    }, [currentPage]);

    const onChagePage = (page) => {
        setCurrentPage(page);
    };

    const onClickDiary = (diaryCode) => {
        navigate(`/diary/${diaryCode}`);
    };

    const onClickWrite = () => {
        navigate("/diary/write");
    };

    const button = () => {
        return (
            <Li>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickWrite()}
                >
                    글쓰기
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <DiaryListBox>
                <DiaryHeader>
                    <DiaryHeaderTitle>제목</DiaryHeaderTitle>
                    <DiaryHeaderDate>봉사날짜</DiaryHeaderDate>
                </DiaryHeader>
                {diaryList.length > 0 ? (
                    diaryList.map((item) => (
                        <DiaryItem
                            key={item.diaryCode}
                            onClick={() => onClickDiary(item.diaryCode)}
                        >
                            <DiaryTitle>{item.diaryTitle}</DiaryTitle>
                            <DiaryDate>{item.diaryDate}</DiaryDate>
                        </DiaryItem>
                    ))
                ) : (
                    <DiaryItem>등록된 일기가 없습니다.</DiaryItem>
                )}
            </DiaryListBox>
            {page && (
                <Pagination
                    currentPage={currentPage}
                    totalElements={page.totalElements}
                    onChangePage={onChagePage}
                />
            )}
        </Layout>
    );
}

export default DiaryList;
