import { useState } from "react";

export const useChangeHandler = (init) => {
    const [state, setState] = useState(init);

    const onChangeHandler = (e) => {
        const { name, value } = e.target;

        setState({ ...state, [name]: value });
    };

    return { state, setState, onChangeHandler };
};

export const useChangeImageHandler = () => {
    const [source, setSource] = useState();
    const [image, setImage] = useState();

    const onChangeImageHandler = (e) => {
        const { files } = e.target;
        const file = files[0];
        setImage(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setSource(reader.result);
        };
    };

    return { source, image, setImage, onChangeImageHandler };
};

export const useChangeMultipleImageHandler = () => {
    const [images, setImages] = useState([]);
    const [previews, setPreviews] = useState([]);

    const onChangeMultipleImageHandler = (e) => {
        const files = Array.from(e.target.files);
        const previews = files.map((file) => URL.createObjectURL(file));
        setImages(files);
        setPreviews(previews);
    };

    return { images, previews, onChangeMultipleImageHandler };
};
