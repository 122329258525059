import React from "react";
import { Route } from "react-router-dom";
import AddDogProfile from "../page/withthedogs/dog/AddDogProfile";
import DogProfile from "../page/withthedogs/dog/DogProfile";
import UpdateDogProfile from "../page/withthedogs/dog/UpdateDogProfile";
import DiaryList from "../page/withthedogs/diary/DiaryList";
import DiaryWirte from "../page/withthedogs/diary/DiaryWrite";
import Diary from "../page/withthedogs/diary/Diary";
import DogList from "../page/withthedogs/dog/DogList";

function WiththedogsRouter() {
    return (
        <>
            <Route path="/dog" element={<DogList />} />
            <Route path="/dog/:shelter" element={<DogList />} />
            <Route path="/dog/new" element={<AddDogProfile />} />
            <Route path="/dog/:shelterCode/:dogCode" element={<DogProfile />} />
            <Route
                path="/dog/update/:shelterCode/:dogCode"
                element={<UpdateDogProfile />}
            />
            <Route path="/diary/list" element={<DiaryList />} />
            <Route path="/diary/write" element={<DiaryWirte />} />
            <Route path="/diary/:diaryCode" element={<Diary />} />
        </>
    );
}

export default WiththedogsRouter;
