import styled from "styled-components";

export const DiaryListBox = styled.div`
    text-align: ${(props) => props.textAlign || "start"};
    padding: 0 200px;
    min-height: calc(100vh - 350px);

    @media (max-width: 768px) {
        width: 100%;
        height: 500px;
        padding: 0 20px;
    }
`;

export const DiaryHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
`;

export const DiaryHeaderTitle = styled.span`
    font-size: 14px;
    width: 70%;
    text-align: center;
    color: #333;
`;

export const DiaryHeaderDate = styled.span`
    font-size: 14px;
    width: 30%;
    text-align: center;
    color: #333;
`;

export const DiaryItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s;

    cursor: pointer;
`;

export const DiaryTitle = styled.span`
    font-size: 14px;
    width: 70%;
    color: #333;
`;

export const DiaryDate = styled.span`
    font-size: 14px;
    width: 30%;
    text-align: center;
    color: #333;
`;
